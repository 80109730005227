<template>
  <VMenu
    v-model="modelValue"
    :activator="activator || undefined"
    :close-on-content-click="closeOnContentClick"
    :location="location"
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
    :target="target"
  >
    <slot />
  </VMenu>
</template>

<script setup lang="ts">
import type { Anchor } from '~/types/pages/components'

const modelValue = defineModel({
  type: Boolean,
  default: false,
})

withDefaults(
  defineProps<{
    activator?: 'parent' | null
    closeOnContentClick?: boolean
    location?: Anchor
    openOnClick?: boolean
    openOnHover?: boolean
    target?: [number, number]
  }>(),
  {
    // デフォルトでは親をactivatorとするが、自分でメニューの開閉を制御したい場合はnullを指定する
    activator: 'parent',
    // Vuetifyではtrueがデフォルト値なので、合わせる
    // eslint-disable-next-line vue/no-boolean-default
    closeOnContentClick: true,
    location: 'bottom',
    // eslint-disable-next-line vue/no-boolean-default
    openOnClick: undefined,
    // eslint-disable-next-line vue/no-boolean-default
    openOnHover: undefined,
    target: undefined,
  }
)
</script>

<style scoped></style>
